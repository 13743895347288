@import "support";

#homeVideo {
  margin: 0px;
  width: 100%;
  height: 100vh;

  ul.videoBG {
    display: block;
    position: relative;
    z-index: 9;
    height: 100vh;
    background-image: url(../img/homeGradiantBG.svg);
    // background: linear-gradient(to bottom,
    //     rgba(255, 255, 255, 0.9) 0%,
    //     rgba(255, 255, 255, 0.7) 20%,
    //     rgba(255, 255, 255, 0.5) 35%,
    //     rgba(238, 195, 87, 0.6) 70%,
    //     rgba(238, 195, 87, 1) 100%);

    li.videoBG {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      height: 100vh;
      // background-image: url(../img/homeLeftBgCurve.svg);
      background: $homeCurveBgSmall;
      background-position: left top;
      background-size: 69% auto;
      background-repeat: no-repeat;
    }
  }

  .videoContainer {
    position: relative;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    bottom: 0;
    right: 0;
  }

  .videoContainer video {
    display: block;
    top: 0%;
    left: 0%;
    min-width: 100%;
    min-height: 100vh;
    width: auto;
    height: auto;
    z-index: -100;
    transition: 1s opacity;
  }

  .videoContainer video::-webkit-media-controls {
    display: none !important;
  }

  .video1-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100vh;
    z-index: -100;
    // background: transparent url(../img/bigscreen.jpg) no-repeat;
    background-size: cover;
  }

  .fallback_smallscreen {
    display: none;
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    bottom: 0;
    right: 0;
    opacity: 0.9;
    // background: transparent url(../img/smallscreen.jpg) no-repeat;
    // background-size: cover;
    background: linear-gradient(to bottom,
        rgba(255, 255, 255, .1) 0%,
        rgba(238, 195, 87, 0.8) 50%,
        rgba(238, 195, 87, .75) 100%);
  }

  @media ($smaller-than: $breakpoint-799) {
    .videoContainer {
      display: none !important;
    }

    .videoContainer video {
      display: none !important;
    }

    .video1-bg {
      display: none !important;
    }

    .fallback_smallscreen {
      display: block !important;
    }
  }

  .videoContent_blk {
    position: absolute;
    top: 130px;
    width: 100%;
    z-index: 15;

    @keyframes blink {
      0% {
        color: $redDark;
      }

      50% {
        color: $blueDark;
      }

      100% {
        color: $redDark;
      }
    }

    ul.videoContent {
      display: block;

      li.videoContent {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 2);
        width: calc(100% / 2);

        &:first-child {
          padding-top: 40px;

          .vc_line1 {
            display: block;
            font-size: 1.35rem;
            color: $lightBlack;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .vc_line2 {
            display: block;
            font-size: 3rem;
            color: $brown;
            font-weight: bold;
            margin-bottom: 20px;
            line-height: 3.3rem;
          }

          .vc_line3 {
            display: block;
            font-size: 1.1rem;
            color: $black;
            margin-bottom: 30px;
            width: 70%;
            line-height: 1.6rem;
          }

          .vcButtonBlk {
            display: block;

            .vcButton {
              position: relative;
              padding: 10px 15px;
              padding-right: 30px;
              font-size: 1.5rem;
              font-weight: bold;
              color: $white;
              text-transform: capitalize;
              border: 0px;
              background: $redDark;
              outline: none;
              border-radius: 5px;
              cursor: pointer;

              &::after {
                content: url('../img/rightArrow.svg');
                display: inline-block;
                width: 20px;
                height: 33px;
                vertical-align: middle;
                margin-left: 15px;
              }

              &:hover {
                background-color: $brown;
              }
            }
          }

          @media ($smaller-than: $breakpoint-799) {
            .vc_line1 {
              font-size: .9175rem;
              margin: 5px 0 0 0;
              padding: 0px;
              text-align: center;
            }

            .vc_line2 {
              font-size: 1.4rem;
              line-height: 1.5rem;
              margin: 2px auto 12px auto;
              text-align: center;
              width: 90%;
            }

            .vc_line3 {
              display: none;
              font-size: .875rem;
              width: 100%;
              line-height: 1rem;
              margin-bottom: 8px;
              text-align: center;
            }

            .vcButtonBlk {
              text-align: center;

              .vcButton {
                padding: 8px 13px 8px 13px;
                font-size: .85rem;
                border-radius: 5px;
                background: rgba(127, 20, 22, 0.85);

                &::after {
                  width: 15px;
                  height: 18px;
                  margin-left: 10px;
                }
              }
            }
          }
        }

        // @media ($smaller-than: $breakpoint-799) {
        //   &:nth-child(1) {
        //     margin: 0 0 20px 0;
        //     padding-top: 20px;
        //   }
        // }

        &:last-child {
          text-align: right;

          .joinCommunity {
            float: right;
            width: 400px;
            margin-right: 25px;

            .joinHeadBlk {
              display: block;
              border-radius: 25px 25px 0 0;
              background: rgba(255, 255, 255, 0.6);
              padding: 1rem 0 .8rem 0;
              margin-bottom: 1px;

              h1 {
                display: block;
                font-size: 1.35rem;
                color: $redDark;
                text-align: center;
                padding-bottom: 7px;
              }

              h2 {
                display: block;
                font-size: .85rem;
                color: $lightBlack;
                text-align: center;
                width: 90%;
                margin: 0 auto;
              }
            }

            @media ($smaller-than: $breakpoint-799) {
              .joinHeadBlk {
                border-radius: 15px 15px 0 0;
                padding: .6rem 0 .6rem 0;
                margin-bottom: 2px;

                h1 {
                  font-size: 1.20rem;
                }

                h2 {
                  font-size: .7rem;
                  width: 80%;
                }
              }
            }

            .joinFormContainer {
              display: block;
              border-radius: 0 0 25px 25px;
              background: rgba(255, 255, 255, 0.6);
              padding: 1.25rem 2.5rem 1rem 2.5rem;

              ul.joinFormContainerIIcol {
                display: block;

                li.joinFormContainerIIcol {
                  display: block;
                }
              }

              ::-webkit-input-placeholder {
                color: $offWhiteDark;
              }

              :-moz-placeholder {
                color: $offWhiteDark;
              }

              ::-moz-placeholder {
                color: $offWhiteDark;
              }

              :-ms-input-placeholder {
                color: $offWhiteDark;
              }

              input {
                padding: 0.75rem 0.7rem 0.75rem 2.75rem;
                font-size: 1rem;
                width: 100%;
                border: 1px solid $white;
                border-radius: 5px;
                box-sizing: border-box;
                background: rgba(255, 255, 255, 0.8);
                margin-bottom: 10px;
              }

              input[type="number"] {
                padding-right: 3.6rem;
              }

              ul.joinFormContainerIIcol {

                li.joinFormContainerIIcol {
                  &:last-child {
                    input[type="number"] {
                      padding-right: .5rem;
                    }
                  }
                }
              }

              .getOtpBtnBlk {
                float: right;
                width: 65px;
                position: relative;
                top: 10px;
                margin-right: 8px;

                .getOtpBtn {
                  position: absolute;
                  display: block;
                  padding: 4.5px 0;
                  border: 0px;
                  border-radius: 5px;
                  font-size: .8rem;
                  color: $disable;
                  font-weight: bold;
                  outline: none;
                  background: transparent;
                  background: $bgTexure;
                  border: 1px solid $offWhite;
                  text-align: center;
                  cursor: pointer;

                  &:hover {
                    border-color: $offWhiteDark;
                    color: $brownBlack;
                  }
                }
              }

              @media ($smaller-than: $breakpoint-799) {
                input {
                  padding: 0.5rem 0.6rem 0.5rem 2rem;
                  font-size: 0.9rem;
                  background: rgba(255, 255, 255, .9);
                }

                .getOtpBtnBlk {
                  width: 55px;
                  top: 5px;
                  margin-right: 6px;

                  .getOtpBtn {
                    font-size: .7rem;
                  }
                }
              }

              .userIcon,
              .phIcon,
              .mailIcon {
                position: absolute;
                float: left;
                height: 40px;
                aspect-ratio: 1/1;
                margin: 1px 0 0 1px;
                opacity: .85;
              }

              .userIcon {
                background: $userFormIcon_SVG;
                background-size: 1rem;
              }

              .phIcon {
                background: $phFormIcon_SVG;
                background-size: 1rem;
              }

              .mailIcon {
                background: $mailFormIcon_SVG;;
                background-size: 1rem;
              }

              .userIcon,
              .phIcon,
              .mailIcon {
                background-position: center center;
                background-repeat: no-repeat;
              }

              @media ($smaller-than: $breakpoint-799) {

                .userIcon,
                .phIcon,
                .mailIcon {
                  height: 35px;
                  background-size: .8rem;
                }
              }

              .termsAgree {
                display: block;
                text-align: center;
                font-size: .7rem;
                color: $lightBlack;
                margin-top: -5px;

                a {
                  color: $lightBlack;
                  border-bottom: 1px dashed $lightBlack;

                  &:hover {
                    color: $redDark;
                  }
                }
              }

              .buttonLabel {
                width: 130px;
                margin: -3px auto 0 auto;

                .joinButton {
                  padding: 0.65rem 1rem;
                  font-size: 1rem;
                  font-weight: bold;
                  color: $white;
                  background: $redDark;
                  border: none;
                  border-radius: 5px;
                  cursor: pointer;
                  margin-top: 1rem;
                  align-self: center;

                  &:hover {
                    background-color: $red;
                  }
                }

                @media ($smaller-than: $breakpoint-799) {
                  .joinButton {
                    padding: 0.4rem 0rem;
                    font-size: .8rem;
                    background: rgba(127, 20, 22, 0.85);
                  }
                }
              }

              .successMessage,
              .errorMessage {
                color: $success;
                margin-top: 10px;
                border-radius: 5px;
                text-align: center;
                font-weight: bold;
                font-size: .85rem;
                background: $white;
                padding: 5px 8px;
                border: 1px solid $offWhiteDark;
              }
              
              .errorMessage {
                color: $alert;
              }

              @media ($smaller-than: $breakpoint-799) {
                .buttonLabel {
                  width: 110px;
                }
              }
            }

            @media ($smaller-than: $breakpoint-799) {
              .joinFormContainer {
                border-radius: 0 0 15px 15px;
                // background: rgba(255, 255, 255, 0.5);
                padding: .85rem 1.25rem .4rem 1.25rem;
              }
            }

            .socialMedia {
              width: 220px;
              margin: 0 auto;
              margin-top: 12px;

              .socialMediaIcons {
                display: flex;
                justify-content: center;
                gap: 5px;

                a {
                  i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    transition: background-color 0.3s, transform 0.3s;

                    &:hover {
                      transform: scale(1.1);
                    }
                  }

                  .instaIcon,
                  .twitterIcon,
                  .youtubeIcon,
                  .facebookIcon,
                  .whatsAppIcon {
                    width: 35px;
                    aspect-ratio: 1/1;
                  }

                  .instaIcon {
                    background: $instaIcon_SVG;
                  }

                  .twitterIcon {
                    background: $twitterIcon_SVG;
                  }

                  .youtubeIcon {
                    background: $youtubeIcon_SVG;
                  }

                  .facebookIcon {
                    background: $facebookIcon_SVG;
                  }

                  .whatsAppIcon {
                    background: $whatsappIcon_SVG;
                  }

                  .instaIcon,
                  .twitterIcon,
                  .youtubeIcon,
                  .facebookIcon,
                  .whatsAppIcon {
                    background-position: center center;
                    background-repeat: no-repeat;
                  }

                  @media ($smaller-than: $breakpoint-799) {

                    .instaIcon,
                    .twitterIcon,
                    .youtubeIcon,
                    .facebookIcon,
                    .whatsAppIcon {
                      width: 25px;
                    }
                  }
                }
              }
            }
          }

          @media ($smaller-than: $breakpoint-799) {
            .joinCommunity {
              width: 100%;
              margin: 0px auto;
            }
          }
        }
      }

      @media ($smaller-than: $breakpoint-799) {
        li.videoContent {
          display: block;
          width: 100%;

          &:nth-child(1) {
            margin: 0 0 20px 0;
            padding-top: 20px;
          }
        }
      }
    }

    .widthfix_corousel {
      width: 100%;
      margin: 0px auto;

      .widthfix_corousel_content {
        display: block;
        padding: 0px;
      }

      @media ($smaller-than: $breakpoint-1300) {
        .widthfix_corousel_content {
          padding: 0px;
        }
      }
    }

    @media ($smaller-than: $breakpoint-1400) {
      .widthfix_corousel {
        width: 100%;
      }
    }

    ul.homeText_Quote_blk {
      display: block;

      li.homeText {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 2);
        width: calc(100% / 2);
        padding-top: 60px;

        .homeMainTxtBlock {
          display: block;

          h1 {
            display: block;

            font-weight: bold;
            font-size: 2.8125rem;
            color: $blueDark;
            margin: 0px 0px 25px 0px;

            .mainTxt_line {
              display: block;
              margin-bottom: 4px;
            }
          }

          @media ($smaller-than: $breakpoint-799) {
            h1 {
              font-size: 2.125rem;
              margin-bottom: 25px;
            }
          }

          @media ($smaller-than: $breakpoint-450) {
            h1 {
              font-size: 1.5rem;
              margin-bottom: 18px;
            }
          }
        }

        .secondaryTxt {
          display: block;

          font-size: 1.25rem;
          color: $brownBlack;
          margin: 0px 0px 30px 0px;
          opacity: 0.9;
          line-height: 30px;

          .secondaryTxt_line {
            display: block;
          }
        }

        @media ($smaller-than: $breakpoint-799) {
          .secondaryTxt {
            width: 100%;
          }
        }

        @media ($smaller-than: $breakpoint-450) {
          .secondaryTxt {
            font-size: 1.125rem;
            line-height: 22px;
            margin-bottom: 20px;
          }
        }

        .homeButLabel {
          display: block;

          .but_learnMore {
            display: inline-block;
            vertical-align: top;
            padding: 12px 30px;
            border-radius: 30px;
            border: none;
            font-weight: bold;
            font-size: 1rem;
            color: $brownBlack;
            background: $bgTransparent_85;
            opacity: 0.85;
            outline: none;
            transition: background 0.5s;
            -webkit-transition: background 0.5s;
          }

          .but_learnMore:hover {
            opacity: 1;
            cursor: pointer;
          }

          @media ($smaller-than: $breakpoint-799) {

            .but_learnMore {
              padding: 10px 25px;
              border-radius: 10px;
            }
          }

          @media ($smaller-than: $breakpoint-450) {

            .but_learnMore {
              padding: 8px 15px;
              height: auto;
              border-radius: 5px;
              font-size: 1rem;
              background: $white;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  @media ($smaller-than: $breakpoint-450) {
    .videoContent_blk {
      top: 60px;
      padding: 0 0px 0 0;
      position: none;
      visibility: visible;
      left: 0px;
      position: fixed;
      z-index: 19;
    }
  }
}

#home_bottom_blk {
  display: block;
  visibility: visible;
  width: 100%;
  height: 100px;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 19;

  ul.home_bot_ver_col {
    display: block;
    margin-top: 20px;
    padding: 0px;

    li.home_bot_ver_col {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 5 - 24px);
      width: calc(100% / 5 - 24px);
      margin-right: 30px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.7);
      border-bottom: 0px;
      border-radius: 20px 20px 0px 0px;
      padding: 19px 0 15px 0;

      &:last-child {
        margin-right: 0px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.65);
      }
    }

    @media ($smaller-than: $breakpoint-799) {
      li.home_bot_ver_col {
        width: -webkit-calc(100% / 2 - 5px);
        width: calc(100% / 2 - 5px);
        margin-right: 10px;
        border-radius: 10px 10px 0px 0px;
        height: 54px;
        padding-top: 10px;
        background: rgba(255, 255, 255, 0.7);
        border: 1px solid rgba(255, 255, 255, 1);

        &:nth-child(2) {
          margin-right: 0px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }

    ul.iconTxtBlk {
      display: block;

      li.iconBlk {
        display: inline-block;
        vertical-align: top;
        width: 70px;
      }

      li.txtBlk,
      li.txtBlkXorai,
      li.txtBlkXoraiShort {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% - 70px);
        width: calc(100% - 70px);
        padding: 2px 15px 0px 10px;
        font-size: 1rem;
        font-weight: bold;
        color: $brown;
      }

      li.txtBlkXoraiShort {
        display: none;
      }

      .botIcon1,
      .botIcon2,
      .botIcon3,
      .botIcon4,
      .botIcon5,
      .botIcon6 {
        width: 70px;
        height:45px;
      }

      .botIcon1 {
        background-image: url(../img/iconBot_1.svg);
      }

      .botIcon2 {
        background-image: url(../img/iconBot_2.svg);
      }

      .botIcon3 {
        background-image: url(../img/iconBot_3.svg);
      }

      .botIcon4 {
        background-image: url(../img/iconBot_4.svg);
      }

      .botIcon5 {
        background-image: url(../img/iconBot_5.svg);
      }

      .botIcon6 {
        background-image: url(../img/iconBot_6.svg);
      }

      .botIcon1,
      .botIcon3,
      .botIcon4 {
        background-size: 45px;
        background-position: top right;
        background-repeat: no-repeat;
      }

      .botIcon2 {
        background-size: 55px;
        background-position: top 2px right;
        background-repeat: no-repeat;
      }

      .botIcon5 {
        background-size: 40px;
        background-position: top 2px right;
        background-repeat: no-repeat;
      }

      .botIcon6 {
        background-size: 35px;
        background-position: top 2px right;
        background-repeat: no-repeat;
      }

      @media ($smaller-than: $breakpoint-799) {
        li.iconBlk {
          width: 40px;
        }

        li.txtBlk,
        li.txtBlkXorai,
        li.txtBlkXoraiShort {
          width: -webkit-calc(100% - 40px);
          width: calc(100% - 40px);
          padding: 2px 0px 0px 10px;
          font-size: .85rem;
        }

        li.txtBlkXorai {
          display: none;
        }

        li.txtBlkXoraiShort {
          display: inline-block;
          vertical-align: top;
        }

        .botIcon1,
        .botIcon6 {
          width: 100%;
          aspect-ratio: 1/1;
          background-size: 20px;
          background-position: top right;
          background-repeat: no-repeat;
        }
      }
    }

    // @media ($smaller-than: $breakpoint-799) {
    //   li.home_bot_ver_col:nth-child(even) {
    //     min-height: 100px;
    //     margin-top: 0px;
    //   }

    //   .botIcon_blk {
    //     width: 50px;
    //   }

    //   .botTextHead {
    //     font-size: 2.5rem;
    //   }

    //   .botTextDesc {
    //     margin: 0px auto 15px auto;
    //     width: 80%;
    //   }
    // }

    // @media ($smaller-than: $breakpoint-450) {

    //   .botTextHead {
    //     font-size: 1.875rem;
    //   }

    //   .botTextDesc {
    //     font-size: .8125rem;
    //   }
    // }
  }

  // @media ($smaller-than: $breakpoint-799) {
  //   ul.home_bot_ver_col {
  //     width: 90%;
  //   }
  // }

  // @media ($smaller-than: $breakpoint-450) {
  //   ul.home_bot_ver_col {
  //     width: 100%;
  //   }
  // }
}

/*Bigger than 249px */
@media ($bigger-than: $breakpoint-249) {
  #home_bottom_blk {
    top: auto;
    bottom: 0px;
    position: absolute;
  }
}

/*Smaller than 1300px */
@media ($smaller-than: $breakpoint-1300) {
  #home_bottom_blk {
    height: auto;
  }
}

@media ($smaller-than: $breakpoint-799) {
  #home_bottom_blk {
    height: 75px;
  }
}

@media screen and (max-height: $breakpoint-499) {
  #home_bottom_blk {
    display: none;
  }
}

/*Membership*/
.membershipContainer {
  display: block;
  background-image: $bgTexure;
  padding: 0 0 40px 0;
  color: $white;

  .memberHeadBg {
    display: block;
    background-image: $bgTexure;
    background-color: $brownBlackDark;
    padding: 40px 0 0 0;
    color: $white;

    .inPgHead,
    .inPgHeadDesc,
    .membershipLink {
      display: block;
      text-align: center;
    }

    .inPgHead {
      font-size: .9rem;
      margin-bottom: 3px;
    }

    .inPgHeadDesc {
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 3px;
    }

    .membershipLink {
      padding-bottom: 130px;

      a {
        font-size: .9rem;
        color: $offWhiteDark;
        border-bottom: 1px dashed $offWhiteDark;

        &:hover {
          color: $white;
        }
      }
    }

    .inPgHead:before,
    .inPgHead:after {
      padding: 0 10px;
      content: '||';
      font-size: .75rem;
    }
  }


}

@media ($smaller-than: $breakpoint-799) {
  .membershipContainer {
    display: none;
  }
}

//Pop Event
.popEventTable {
  display: block;

  .headLabel {
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 1.25rem;
    color: $redDark;
  }

  button {
    display: inline-block;
    margin-top: 10px;
    text-align: center;
  }

  .buttonLabel {
    display: block;
    text-align: center;
    margin-top: 5px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: .85rem;

    th,
    td {
      border: 1px solid $offWhiteDark;
      padding: 8px;
      text-align: left;
    }

    th {
      background-image: $bgTexure;
      background: $offWhite;
    }
  }
}