@import "support";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Use the variables */
body {
  font-family: $font-arial;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  color: $black;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body>#root {
  width: 100vw;
  height: 100vh;
}

.uttaranaLogo {
  background-image: url(../img/Uttarana_Logo.svg);
  background-repeat: no-repeat;
  // background: $uttarana_logo_SVG;
}

/*General Responsiveness*/
@media ($bigger-than: $breakpoint-1300) {
  .widthfix {
    width: $width-1300px;
    margin: 0px auto;
  }
}

@media ($smaller-than: $breakpoint-1300) {
  .widthfix {
    width: auto;
    margin: 0px 10px;
  }
}

ul.contentIIcol {
  display: block;
  margin: 0px;
  padding: 0px;

  li.contentIIcol {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 2 - 15px);
    width: -webkit-calc(100% / 2 - 15px);
    margin: 0px 25px 20px 0px;
  }

  li.contentIIcol:nth-child(even) {
    margin-right: 0px;
  }

  @media ($smaller-than: $breakpoint-599) {
    li.contentIIcol {
      display: block;
      width: 100%;
      margin-right: 0px;
    }
  }
}

/*Disable Background*/
.disable_bg,
.pop_disable_bg {
  position: fixed;
  display: flex;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 2450;
}

.pop_disable_bg {
  display: none;
  background: transparent;
  opacity: 1;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABtJREFUeNpiZGBgOMNABGBiIBKMKqSOQoAAAwBVNQDgpAAyvAAAAABJRU5ErkJggg==");
  z-index: 2510;
}

/*Custom Button*/
.btn100pcRed {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 9px 0px;
  border: 0px;
  border-radius: 5px;
  outline: none;
  font-size: 1rem;
  background-color: $redDark;
  color: $white;
  font-weight: bold;
  cursor: pointer;
}

.btn100pcRed:hover {
  background: $red;
}

.btnRed_S,
.btnRed_S_Bold {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border: 0px;
  border-radius: 4px;
  outline: none;
  font-size: .7rem;
  background-color: $redDark;
  color: $white;
  cursor: pointer;
}

.btnRed_S:hover,
.btnRed_S_Bold:hover {
  background: $black;
}

.btnRed_S_Bold {
  font-weight: bold;
}

.btnRed_M,
.btnRed_M_Bold {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px;
  border: 0px;
  border-radius: 4px;
  outline: none;
  font-size: .8rem;
  background-color: $redDark;
  color: $white;
  cursor: pointer;
}

.btnRed_M:hover,
.btnRed_M_Bold:hover {
  background: $black;
}

.btnRed_M_Bold {
  font-weight: bold;
}

.buttonRed,
.buttonRed100pc {
  padding: 8px 20px 10px 20px;
  border: 0px;
  border-radius: 7px;
  outline: none;
  font-size: .9175rem;
  font-weight: bold;
  background-color: $redDark;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $red;
  }
}

.buttonRed100pc {
  width: 100%;
  padding: 8px 0px 10px 0px;
  text-align: left;
}

.marLeft5 {
  margin-left: 5px;
}

.padBot20 {
  display: block;
  padding-bottom: 20px;
}

/*Common Form Styles*/
.formMandatory {
  display: inline-block;
  vertical-align: top;
  margin-left: 3px;
  height: 3px;
  font-size: 1.5rem;
  color: $redDark;
}

.infoIcon {
  float: right;
  width: 20px;
  height: 15px;
  background: $infoIcon_SVG;
  background-size: 14px;
  position: relative;
  cursor: pointer;

  &:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
}

.tooltipText {
  visibility: hidden;
  position: absolute;
  width: 150px;
  background: $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: $brownBlack;
  text-align: center;
  border-radius: 4px;
  font-size: .7rem;
  padding: 10px;
  z-index: 1;
  bottom: 125%;
  right: 0;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.infoIcon .no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-input-placeholder {
  color: $lightestBlack;
}

:-moz-placeholder {
  color: $lightestBlack;
}

::-moz-placeholder {
  color: $lightestBlack;
}

:-ms-input-placeholder {
  color: $lightestBlack;
}

select:required:invalid {
  color: $disable;
}

option {
  color: $blue;
}

select::-ms-expand {
  display: none;
}

select {
  display: block;
  width: 100%;
  font-size: 1rem;
  color: $brownBlack;
  padding: 10px 15px;
  border: 1px solid $lighterBlack;
  background-color: rgba(244, 244, 244, .5);
  outline: 0;
  background: $selectarrow_blackIcon_SVG;
  background-position: right 3% center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

select:hover {
  cursor: pointer;
  background: $selectarrow_redIcon_SVG;
  background-position: right 3% center;
  background-color: $white;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea {
  display: block;
  width: 100%;
  font-size: .9rem;
  color: $black;
  padding: 10px 15px;
  border: 1px solid $lighterBlack;
  background: $white;
  outline: 0;
}

input[type="file"] {
  width: 95%;
  font-size: .8rem;
  color: $brownBlack;
  outline: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
  cursor: pointer;
}

input[type="checkbox"]:not([disabled])+label,
input[type="radio"]:not([disabled])+label {
  cursor: pointer;
}

input[type="checkbox"]+label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin: -7px 5px 0px 0;
  border: 1px solid $lightBlack;
  border-radius: 3px;
  background: $white;
}

input[type="checkbox"]:checked+label:before {
  content: "\2714";
  color: $brownBlack;
  font-size: .9rem;
  text-align: center;
  line-height: 18px;
}

input[type="radio"]+label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -5px;
  background: $white;
  border-radius: 50%;
  border: 1px solid $offWhiteDark;
}

input[type="radio"]:checked+label:before {
  content: "\2022";
  color: $offWhiteDark;
  font-size: 1.8rem;
  text-align: center;
  line-height: 23px;
  border: 1px solid $offWhiteDark;
}

//Common Text Style
.contentPad20 {
  display: block;
  padding-top: 20px;
}

.pgHeadText {
  display: block;
  font-size: 1.5rem;
  color: $redDark;
  font-weight: bold;
  line-height: 1.8rem;
  margin-bottom: 10px;
  border-bottom: 1px solid $offWhiteDark;
  margin: 0 -30px;
  padding: 0 25px 10px 25px;
}

@media ($smaller-than: $breakpoint-799) {
  .pgHeadText {
    font-size: 1.15rem;
    padding-left: 35px;
  }
}

.txtBoldBig {
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  color: $blueDark;
  margin: 10px 0px 20px 0px;
}

.pgTxtNormal,
.pgTxtBold,
.pgTxtBoldRed {
  display: block;
  font-size: 1rem;
  text-align: justify;
  margin: 0px 0px 20px 0px;
  color: $brownBlack;

  a {
    color: $blue;
    border-bottom: .5px dashed $blue;

    &:hover {
      color: $redDark
    }
  }
}

.pgTxtBold {
  font-weight: bold;
  margin-bottom: 10px;
}

.pgTxtBoldRed {
  font-weight: bold;
  margin-bottom: 10px;
  color: $redDark;
}

@media ($smaller-than: $breakpoint-799) {
  .txtBoldCaps {
    font-size: 1.35rem;
  }
}

@media ($smaller-than: $breakpoint-450) {
  .txtBoldCaps {
    font-size: 1.1rem;
  }

  .pgTxtNormal {
    font-size: 0.875rem;
  }

  .pgTxtBold {
    font-size: 0.9125rem;
  }
}

ul.bulletPoints_un {
  list-style: none;
  margin: 0px 0px 20px 15px;
  font-size: 1rem;
  color: $brownBlack;
  text-align: justify;

  a {
    color: $blue;
    border-bottom: .5px dashed $blue;

    &:hover {
      color: $redDark
    }
  }

  .bp_indent_30 {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  .bp_indent_60 {
    margin-left: 60px;
    margin-bottom: 25px;
  }

  .bp_indent_90 {
    margin-left: 90px;
    margin-bottom: 25px;
  }

  li.bp_squareOutline,
  li.bp_circleOutline,
  li.bp_circle,
  li.bp_circleFilled,
  li.bp_circleDouble {
    margin: 0px 0px 15px 30px;
  }

  li.bp_squareOutline::before,
  li.bp_circleOutline::before,
  li.bp_circle::before,
  li.bp_circleFilled::before,
  li.bp_circleDouble:before {
    color: $redDark;
    margin-left: -30px;
    padding-right: 15px;
  }

  li.bp_squareOutline::before {
    content: "\01f795";
  }

  li.bp_circleOutline::before {
    content: "\0025c9";
  }

  li.bp_circle::before {
    content: "\01f53e";
    font-size: 1rem;
  }

  li.bp_circleFilled::before {
    content: "\0023fa";
  }

  li.bp_circleDouble::before {
    content: "\0025ce";
  }
}

@media ($smaller-than: $breakpoint-450) {
  ul.bulletPoints_un {
    font-size: 0.875rem;

    li.bp_circleOutline {
      margin-left: 15px;
      margin-bottom: 8px;
    }
  }
}

ul.careerPoints {
  list-style-type: square;
  margin-left: 15px;
  color: $brownBlack;
  text-align: justify;

  li {
    font-size: .9rem;
    text-align: justify;
    margin: 0px 0px 10px 5px;
    color: $brownBlack;
  }
}

//POP Modal
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal,
  .modalEventsHome,
  .modalDonation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    z-index: 1000;
  }

  .modalEventsHome {
    top: 42%;
    width: 480px;
    border-radius: 0px;
  }

  .modalDonation {
    width: 550px;
    top: 40%;
  }

  .modalHead {
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
    color: $black;
    padding: 15px 20px 15px 20px;
    margin: -20px -20px 15px -20px;
    background: $bgTexure;
    border-bottom: 1px solid $offWhiteDark;
  }

  .modalClose {
    float: right;
    margin-top: -5px;

    .popCloseButton {
      font-size: 1.1rem;
      font-weight: bold;
      color: $red;
      background: $white;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      align-self: center;
      background: transparent;


      &:hover {
        color: $black;
      }
    }
  }

  @media ($smaller-than: $breakpoint-799) {

    .modalDonation {
      width: 95%;
      top: 50%;
    }

    .modalHead {
      padding: 10px 20px 10px 20px;
    }

    .modalClose {
      margin-top: -8px;
    }
  }
}
.height10 {
  display: block;
  height:10px;
}
.height15 {
  display: block;
  height:15px;
}
.height25 {
  display: block;
  height:25px;
}