@import "support";


/*Footer Common*/
#footer {
	clear: both;
	width: 100%;
	position: relative;
	z-index: 10;
	height: 30px;
	margin-top: -30px;
	background: $brownBlack;

	.footer_blk {
		display: block;

		.footerInpage {
			display: block;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: .9rem;
			color: $white;

			a {
				text-decoration: none;
				color: $white;
			}

			a:hover {
				color: $offWhiteLight;
			}
		}

		ul.footerLinks {
			display: block;
			text-align: center;

			li.footerLinks {
				display: inline-block;
				vertical-align: top;
				font-weight: bold;
				font-size: .9rem;
				color: $black;
				padding: 10px 0px 3px 0px;
			}

			li.footerLinks::after {
				content: "\00007c";
				padding: 0px 10px;
				color: $lightBlack;
			}

			li.footerLinks:last-child::after {
				content: "";
			}

			li.footerLinks a {
				color: $black;
			}

			li.footerLinks a:hover {
				color: $redDark;
				cursor: pointer;
			}

			@media ($smaller-than: $breakpoint-599) {

				// li.footerLinks:nth-child(2),
				li.footerLinks:last-child,
				li.footerLinks:nth-last-child(2) {
					display: none;
				}

				li.footerLinks:nth-child(2):after {
					content: "";
				}
			}

			@media ($smaller-than: $breakpoint-599) {
				li.footerLinks a {
					color: $offWhite;
				}
			}
		}

		.footer_copyright {
			display: block;
			font-size: .8rem;
			color: $lightBlack;
			text-align: center;
		}

		@media ($smaller-than: $breakpoint-599) {

			.footer_copyright {
				color: $offWhite;
			}
		}
	}
}

@media ($smaller-than: $breakpoint-599) {
	#footer {
		background: $brownBlack;
		background-image: $bgTexure;
	}
}

//Footer Home
#footer_home {
	background: $brownBlack;
	padding: 50px 0px 30px 0px;

	ul.footerVerCol {
		display: block;

		.footerHeadText {
			display: block;
			font-weight: bold;
			font-size: 1.25rem;
			color: $offWhite;
			margin-bottom: 20px;
			// text-transform: uppercase;
		}

		li.footerVerCol {
			display: inline-block;
			vertical-align: top;
			margin-right: 50px;
			width: calc(100% / 4 - 20px);
			width: -webkit-calc(100% / 4 - 20px);
			margin-right: 25px;
		}

		li.footerVerCol:last-child {
			margin-right: 0px;
		}

		.footerLogoSetting {
			height: 90px;
			width: 100%;
			margin-top: 5px;
			background-position: left top;
			background-size: 110px;
			// opacity: .8;
			margin-bottom: 15px;
		}

		.footerCopyright {
			display: block;
			font-size: .9rem;
			color: $offWhite;
			margin-bottom: 5px;
		}

		.footerTerms {
			display: block;
			text-decoration: none;
			font-size: 1rem;
			color: $offWhite;

			.termsLink {
				font-size: 1rem;
				color: $offWhite;
				opacity: .7;
				-webkit-transition: all .5s ease-in-out;
				-moz-transition: all .5s ease-in-out;
				transition: all .5s ease-in-out;
			}

			.termsLink:hover {
				opacity: 1;
			}
		}

		@media ($smaller-than: $breakpoint-799) {
			li.footerVerCol {
				display: block;
				width: 100%;
				margin: 0px;
				padding-bottom: 15px;
			}

			li.footerVerCol:nth-child(2),
			li.footerVerCol:nth-child(3),
			li.footerVerCol:last-child {
				display: none;
			}

			.footerLogoSetting {
				background-position: center center;
				background-size: 100px;
				margin-bottom: 0px;
			}

			.footerCopyright,
			.footerTerms {
				font-size: .8rem;
				text-align: center;

				.termsLink {
					font-size: .9rem;
				}
			}
		}

		.footerNotetxt {
			display: block;
			font-size: .6rem;
			color: $offWhite;
			margin-right: 5px;
		}

		.footerContact {
			display: block;
			font-size: .9rem;
			color: $offWhite;
			opacity: .7;
			margin-bottom: 10px;
		}

		ul.footerContTxtIconBlk {
			display: block;

			li.footerContTxtIconBlk:nth-child(odd) {
				display: inline-block;
				vertical-align: top;
				width: 35px;
			}

			li.footerContTxtIconBlk:nth-child(even) {
				display: inline-block;
				vertical-align: top;
				width: calc(100% - 35px);
				width: -webkit-calc(100% - 35px);
				font-size: .9rem;
				color: $offWhite;
				opacity: .7;
				margin-bottom: 10px;
			}

			.fotPhIcon,
			.fotMailIcon {
				width: 35px;
				aspect-ratio: 1/1;
				opacity: .5;
			}

			.fotPhIcon {
				background: $PhIconWhite_SVG;
				background-size: 20px;
				background-position: left top;
			}

			.fotMailIcon {
				background: $MailIconWhite_SVG;
				background-size: 20px;
				background-position: left top;
			}
		}

		ul.footerQuickLink {
			display: block;

			li.footerQuickLink {
				display: block;
				margin-bottom: 8px;
			}

			li.footerQuickLink .quickLink {
				font-size: .9rem;
				color: $offWhite;
				opacity: .7;
				-webkit-transition: all .3s ease-in-out;
				-moz-transition: all .3s ease-in-out;
				transition: all .3s ease-in-out;
			}

			li.footerQuickLink .quickLink:hover {
				opacity: 1;
			}
		}

		.footerGetUpdate {
			display: block;
			font-size: .9rem;
			color: $offWhite;
			opacity: .7;
			margin-bottom: 10px;
		}

		.fotterGetUpdateFormBlk {
			display: block;
			height: 45px;
			position: relative;

			::-webkit-input-placeholder {
				color: $lightestBlack;
				opacity: .7;
			}

			:-moz-placeholder {
				color: $lightestBlack;
				opacity: .7;
			}

			::-moz-placeholder {
				color: $lightestBlack;
				opacity: .7;
			}

			:-ms-input-placeholder {
				color: $lightestBlack;
				opacity: .7;
			}

			input[type="email"] {
				height: 45px;
				width: 100%;
				padding: 0px 50px 0px 15px;
				border: none;
				border-radius: 50px;
				font-size: 1rem;
				background: $offWhiteLight;
				color: $black;
				outline: 0;
				margin-bottom: 10px;
			}

			.footerBtnBlk {
				position: absolute;
				right: 5px;
				top: 5px;
				height: 35px;
				aspect-ratio: 1/1;
				border-radius: 50px;
				background: $buttonArrowIcon_SVG $brownBlack;
				background-position: center center;
				background-size: 18px;
				// opacity: .4;
			}

			.footerBtnBlk:hover {
				opacity: .6;
			}
		}
	}

	ul.siteDeveloper {
		display: block;
		margin: 15px 0 0 20px;
		color: $lightBlack;
		font-size: .85rem;

		.devHead {
			display: block;
			font-weight: bold;
			margin-bottom: 2px;
			text-decoration: underline;
		}

		li {
			display: inline-block;
			margin-right: 5px;

			a {
				color: $lightBlack;

				&:hover {
					color: $lightestBlack;
				}
			}
		}
	}
}

@media ($smaller-than: $breakpoint-1200) {
	#footer_home {
		padding: 15px 0px 0px 0px;
	}
}

@media ($smaller-than: $breakpoint-450) {
	#footer_home {
		display: none;
	}
}