@import "support";

body>#root>#container {
  height: auto;
  min-height: 100%;
}

#container {
  color: $black;
  height: 100vh;
  background: $white;
  background-image: $bgTexure;

  #content {
    padding-top: 45px;
    padding-bottom: 15px;
  }

  #content_fullHeader {
    padding-top: 65px;
    padding-bottom: 30px;
  }

  @media ($smaller-than: $breakpoint-1300) {
    #content_fullHeader {
      padding-top: 65px;
    }
  }

  @media ($smaller-than: $breakpoint-450) {
    #content_fullHeader {
      padding-top: 60px;
    }
  }
}

.padBot40 {
  padding-bottom: 55px;
}

.padBot60 {
  padding-bottom: 70px;
}

//Inpage Head Blue Background 
.inPgHeadBlueBg {
  display: block;
  background-image: $bgTexure;
  background-color: $brownBlackDark;
  padding: 40px 0 0 0;
  color: $white;

  /*Breadcrumb Style*/
  ul.breadcrumbLink {
    display: block;
    padding-left: 10px;

    a {
      color: $white;
      cursor: pointer;
    }

    a:hover {
      border-bottom: 1px dotted $offWhite;
    }

    li.breadcrumbLink {
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
      font-size: .9rem;
    }

    li.breadcrumbLink:last-child {
      font-size: 1rem;
      font-weight: bold;
    }

    li.breadcrumbLink::after {
      content: '>';
      padding: 0 8px;
    }

    li.breadcrumbLink:last-child::after {
      content: '';
      padding-right: 0px;
    }
  }

  .inPgHead,
  .inPgHeadDesc,
  .membershipLink {
    display: block;
    text-align: center;
  }

  .inPgHead {
    font-size: .9rem;
    margin-bottom: 3px;
  }

  .inPgHeadDesc {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 3px;
  }

  .membershipLink {
    padding-bottom: 130px;

    a {
      font-size: .9rem;
      color: $offWhiteDark;
      border-bottom: 1px dashed $offWhiteDark;

      &:hover {
        color: $white;
      }
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    .inPgHeadDesc {
      font-size: 1rem;
    }

    .membershipLink {
      padding-bottom: 20px;

      a {
        font-size: .75rem;
      }
    }
  }

  .inPgHead:before,
  .inPgHead:after {
    padding: 0 10px;
    content: '||';
    font-size: .75rem;
  }
}

@media (max-width: $breakpoint-799) {
  .inPgHeadBlueBg {
    padding-top: 20px;
  }
}

.inPageTabs {
  display: flex;
  margin-top: 10px;
  background: $white;
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid $offWhite;
  width: 100%;

  .tab {
    padding: 10px 15px;
    margin-right: 5px;
    border: 0px;
    font-size: .9rem;
    cursor: pointer;
    background-color: $white;
    font-weight: bold;
    color: $brownBlack;

    &.active {
      background-color: $redDark;
      color: $white;
    }

    &.active:hover {
      color: $white;
    }

    &:hover {
      color: $redDark;
    }
  }
}

.tabs-container {
  display: flex;
}

.dropdown-container {
  display: none;

  select {
    background-color: $white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-color: $offWhiteLight;
    font-weight: bold;
  }
}

@media (max-width: $breakpoint-799) {
  .tabs-container {
    display: none;
  }

  .dropdown-container {
    display: block;
  }
}

ul.pgHeading {
  display: block;
  margin-top: -58px;
  background: $white;
  padding: 15px 20px 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  li {
    display: inline-block;
    vertical-align: top;
    width: -webkit-calc(100% / 2);
    width: calc(100% / 2);

    &:nth-child(1) {
      font-size: 1.5rem;
      color: $redDark;
      font-weight: bold;
    }

    &:nth-child(2) {
      text-align: right;
    }
  }

  @media (max-width: $breakpoint-799) {
    li {
      display: block;
      width: 100%;

      &:nth-child(1) {
        font-size: 1.2rem;
      }

      &:nth-child(2) {
        display: none;
      }
    }
  }

  ul.filter {
    margin: -7px 0 0 0;
    width: 400px;
    float: right;

    li {
      display: inline-block;
      vertical-align: top;
    }

    li:nth-child(1) {
      width: 100px;
    }

    li:nth-child(2) {
      width: 150px;
    }

    .filterHead {
      display: block;
      font-size: .7rem;
      text-align: left;
      font-weight: normal;
      color: $brownBlack;
      margin-bottom: 2px;
    }

    .filterDate>select,
    .filterName>select {
      font-size: .8rem;
      margin: 0 0 -5px 0;
      padding: 3px 5px;
      width: 90px;
      border-radius: 3px;
      border-color: $offWhiteDark;
      background-color: $offWhite;
      outline: none;
    }

    .filterName>select {
      width: 150px;
    }
  }
}

//MemberList 
.memberlistTabContent {
  padding: 20px;
  background-color: $white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: .9rem;
  overflow-x: auto; // Add horizontal scrolling for large tables

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      border: 1px solid $offWhiteDark;
      text-align: left;
    }

    th {
      background-color: $offWhite;
    }

    @media ($smaller-than: $breakpoint-799) {

      th:nth-child(2),
      td:nth-child(2) {
        display: none
      }
    }
  }
}

@media ($smaller-than: $breakpoint-799) {
  .memberlistTabContent {
    padding: 10px;
    font-size: .75rem;
  }
}

//Membership
.membership {
  ul.membershipIVcol {
    display: block;
    margin-top: -60px;
    margin-bottom: 25px;

    li {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 4 - 30px);
      width: calc(100% / 4 - 30px);
      margin-left: 40px;

      &:nth-child(1) {
        margin-left: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-799) {

      li,
      &:nth-child(1) {
        display: block;
        width: 98%;
        margin: 0 0 20px 0;
      }
    }
  }

  .memberParentBlk {
    width: 100%;
    background: $offWhiteLight;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 80px 35px 10px 35px;

    .memberIconBlk {
      width: 100px;
      aspect-ratio: 1/1;
      background: $white;
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 1px solid $offWhite;

      .memIcon1,
      .memIcon2,
      .memIcon3,
      .memIcon4 {
        width: 100%;
        aspect-ratio: 1/1;
      }

      // .memIcon1 {
      //   background-image: url(../img/iconMember_1.svg);
      // }

      // .memIcon2 {
      //   background-image: url(../img/iconMember_2.svg);
      // }

      // .memIcon3 {
      //   background-image: url(../img/iconMember_3.svg);
      // }

      // .memIcon4 {
      //   background-image: url(../img/iconMember_4.svg);
      // }

      .memIcon1,
      .memIcon2,
      .memIcon3,
      .memIcon4 {
        background-size: 50px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    @media ($smaller-than: $breakpoint-799) {
      .memberIconBlk {
        display: none
      }
    }

    .memberType {
      display: block;
      margin: 0 auto 15px auto;
      padding: 15px 0;
      background: $red;
      color: $white;
      border-radius: 20px;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;

      &.memberFee {
        display: block;
      }

      .memberBtnBlk {
        display: block;

        .joinButton {
          padding: 0.5rem 1rem;
          font-size: .9rem;
          font-weight: bold;
          color: $red;
          background: $white;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          margin-top: 1rem;
          align-self: center;

          &:hover {
            background-color: $offWhite;
          }
        }
      }
    }

    .memberTypeDesc {
      display: block;
      margin: 0 auto 20px auto;
      color: $black;
      text-align: center;
      font-size: 0.85rem;
    }

    .memberBlkContent {
      display: block;

      .contentHead,
      .contentNormal {
        display: block;
        font-size: 0.85rem;
        font-weight: bold;
        margin: 20px 0 3px 0;
      }

      .contentNormal {
        font-weight: normal;
        margin-top: 0px;
        font-size: 0.8rem;
        color: $brownBlack;
      }

      .contentNote {
        font-size: 0.7rem;
        font-weight: bold;
        color: $lightestBlack;
        margin-bottom: 5px;
      }

      .eligibilityBlk {
        display: block;
        min-height: 180px;

        .eligibilityPoints {
          display: block;

          font-size: .8rem;
          color: $brownBlack;
          padding: 8px 0;
          border-top: 1px dashed $disable;
          text-align: left;

          &:last-child {
            border-bottom: 1px dashed $disable;
          }
        }
      }

      @media ($smaller-than: $breakpoint-799) {
        .eligibilityBlk {
          min-height: 0px;
        }
      }

      .memberBenefitHead {
        display: block;
        font-size: 0.9rem;
        font-weight: bold;
        margin: 20px -10px -5px -10px;
        background: $red;
        color: $white;
        padding: 7px 10px;
      }
    }

    .expandButton {
      display: block;
      margin: -50px auto 20px auto;
      width: 80px;
      padding: 7px 0px;
      font-size: .85rem;
      font-weight: bold;
      color: $white;
      background: $lightBlack;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 20px;

      &:hover {
        color: $offWhite;
      }
    }

    .memberParentBlk.expanded .memberBlkContent {
      display: block;
    }

    @media ($smaller-than: $breakpoint-799) {
      .memberBlkContent {
        margin: 0px 10px;
      }
    }

  }

  @media ($smaller-than: $breakpoint-799) {
    .memberParentBlk {
      position: none;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      padding: 10px;
      margin-top: 15px;
    }
  }
}

//MemberForm
.memberFormBlk {
  display: block;

  .blueBg {
    display: block;
    padding-top: 50px;
    background-color: $blueDark;
  }

  .gradiantColorBlk {
    display: block;
    background: linear-gradient(to right, transparent 50%, $red 50%);
  }

  ul.memberFormIIcol {
    display: block;

    li {
      display: inline-block;
      vertical-align: top;
      width: 300px;
    }

    li:nth-child(1) {
      padding-right: 50px;
    }

    li:nth-child(2) {
      width: -webkit-calc(100% - 300px);
      width: calc(100% - 300px);
      background: $red;
    }

    @media ($smaller-than: $breakpoint-799) {

      li,
      li:nth-child(1),
      li:nth-child(2) {
        display: block;
        width: 100%;
        padding: 0px;
        margin: 0px;
      }

      li:nth-child(1) {
        display: none;
      }

      li:nth-child(2) {
        margin-left: -10px;
      }
    }
  }

  .donatePgHead {
    display: block;
    text-align: center;
    margin: 40px 0 10px 0;
    color: $white;
    font-size: 2.275rem;
    font-weight: bold;
    font-family: $font-arialBlack;
  }

  .donateImage {
    display: block;
    margin: 0px auto;
    width: 100%;
    height: 210px;
    // background-image: url(../img/donation.jpg);
    background-repeat: no-repeat;
    background-size: 250px;
    background-position: left top;
  }

  .memberSubLine {
    display: block;
    text-align: center;
    color: $white;
    font-size: 1rem;
    margin-top: 1rem;
  }

  .signInLink {
    display: block;
    padding: 20px 0;
    margin: 0 5px 0 75px;
    font-size: .85rem;
    font-weight: bold;
    border-bottom: 1px dashed $offWhite;
    color: $white;

    a {
      background: $white;
      text-decoration: none;
      padding: 5px 8px;
      border-radius: 3px;
      color: $blueDark;
      margin: 0 2px;

      &:hover {
        color: $blue;
      }
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    .signInLink {
      margin-left: 20px;
    }
  }

  //Membership form
  .form-container {
    padding: 20px 0px 0 75px;

    ul.formIIIcol {
      display: block;

      li {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 3 - 10px);
        width: calc(100% / 3 - 15px);
        background: $white;
        margin-bottom: 20px;
        padding: 15px;
        border-radius: 5px;
        margin-right: 20px;
      }

      li:nth-child(3n + 0) {
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-799) {

        li,
        li:nth-child(3n + 0) {
          display: block;
          width: 100%;
          margin-bottom: 10px;
          padding: 10px;
        }

        li:nth-child(2) {
          margin-left: 0px;
        }
      }
    }

    form {
      .labelHead {
        display: block;
        font-size: .9rem;
        font-weight: bold;
        border-bottom: 1px dashed $offWhiteDark;
        color: $black;
        padding-bottom: 2px;
      }

      input,
      select {
        font-size: 1rem;
        border: none;
        margin-top: 5px;
        padding: 0px;

        &:focus {
          border-color: $offWhiteDark;
          outline: none;
        }
      }

      select {
        background-position: right -6px center;
      }

      input[type="number"] {
        padding-right: 4rem;
      }

      .getOtpBtnBlk {
        float: right;
        width: 60px;
        position: relative;
        top: 0px;
        margin-right: 0px;

        .getOtpBtn {
          position: absolute;
          display: block;
          padding: 3px 8px;
          border: 0px;
          border-radius: 5px;
          font-size: .7rem;
          color: $lightBlack;
          outline: none;
          background: $bgTexure;
          border: 1px solid $lightestBlack;
          text-align: center;
          cursor: pointer;

          &:hover {
            border-color: $brownBlack;
            color: $brownBlack;
          }
        }
      }

      .buttonLabel {
        display: block;
        margin-top: -20px;

        button {
          padding: 10px 20px;
          font-size: 1.15rem;
          font-weight: bold;
          color: $red;
          background: $white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          margin-top: 20px;

          &:hover {
            color: $redDark;
          }
        }
      }


    }
  }

  @media ($smaller-than: $breakpoint-799) {
    .form-container {
      padding: 15px 0 0 15px;
    }
  }
}

//Offer Xorai
.offerXorai {
  margin-bottom: 20px;

  ul.xoraiIIcol {
    display: block;
    margin-top: -70px;

    li {
      display: inline-block;
      vertical-align: top;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      margin-top: 10px;
      background: $white;
      padding: 20px 30px 30px 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &:nth-child(1) {
        width: 400px;
        margin-right: 30px;
      }

      &:nth-child(2) {
        width: -webkit-calc(100% - 430px);
        width: calc(100% - 430px);
      }

      @media ($smaller-than: $breakpoint-799) {

        li,
        &:nth-child(1),
        &:nth-child(2) {
          display: block;
          width: 100%;
          padding: 20px 15px;
        }

        &:nth-child(1) {
          margin-bottom: 15px;
        }

        &:nth-child(2) {
          border-radius: 0px;
        }
      }
    }
  }

  .signInLink {
    display: block;
    padding: 20px 0;
    font-size: .85rem;
    font-weight: bold;
    border-bottom: 1px dashed $offWhiteDark;

    a {
      background: $brownBlack;
      text-decoration: none;
      padding: 5px 8px;
      border-radius: 3px;
      color: $white;

      &:hover {
        background: $brownBlackDark;
      }
    }
  }

  ul.xoraiAmountVerBlk {
    display: block;
    margin-top: 20px;

    li.xoraiAmountVerBlk {
      display: inline-block;
      vertical-align: top;
      margin: 0px;
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
      background: transparent;

      &:nth-child(1) {
        width: -webkit-calc(100% - 250px);
        width: calc(100% - 250px);
      }

      &:nth-child(2) {
        width: 250px;
        padding-left: 20px;
      }

      @media ($smaller-than: $breakpoint-799) {

        li.xoraiAmountVerBlk,
        &:nth-child(1),
        &:nth-child(2) {
          display: block;
          width: 100%;
          padding: 0px;
        }

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }

    .xoraiAmountHead {
      display: block;
      padding: 8px 10px;
      background: $lightestBlack;
      color: $white;
      font-weight: bold;
      font-size: .875rem;
      border-radius: 4px 4px 0 0;
    }

    ul.xoraiAmountBreakup {
      display: block;

      li.xoraiAmountBreakup {
        display: inline-block;
        vertical-align: top;
        margin: 0px;
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
        background: $bgTexure;
        padding: 10.5px 10px;
        font-size: .85rem;
        border-bottom: 1px solid $offWhiteDark;

        &:nth-child(odd) {
          width: -webkit-calc(100% - 75px);
          width: calc(100% - 75px);
          border-left: 1px solid $offWhiteDark;
          border-right: 1px solid $offWhiteDark;
        }

        &:nth-child(even) {
          width: 75px;
          border-right: 1px solid $offWhiteDark;
          text-align: right;
        }

        &:last-child,
        &:nth-last-child(2) {
          font-weight: bold;

        }

        &:last-child {
          border-bottom-right-radius: 5px;
        }

        &:nth-last-child(2) {
          border-bottom-left-radius: 5px;
        }
      }
    }
  }

  ul.xoraiFormIIIcol {
    display: block;

    li.xoraiFormIIIcol,
    li.xoraiFormIIIcol:nth-child(1),
    li.xoraiFormIIIcol:nth-child(2) {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 3 - 10px);
      width: calc(100% / 3 - 10px);
      margin: 0px 15px 20px 0px;
      box-shadow: none;
      padding: 0px;
      border-radius: 0px;

      &:nth-child(3n + 0) {
        margin-right: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-799) {

      li.xoraiFormIIIcol,
      li.xoraiFormIIIcol:nth-child(1),
      li.xoraiFormIIIcol:nth-child(2) {
        display: block;
        width: 100%;
        margin: 0 0 15px 0;
      }
    }
  }

  ul.xoraiFormIIcol {
    display: block;

    li.xoraiFormIIcol,
    li.xoraiFormIIcol:nth-child(1),
    li.xoraiFormIIcol:nth-child(2) {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 2 - 10px);
      width: calc(100% / 2 - 10px);
      margin: 0px 15px 20px 0px;
      box-shadow: none;
      padding: 0px;
      border-radius: 0px;

      &:nth-child(even) {
        margin-right: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-799) {

      li.xoraiFormIIcol,
      li.xoraiFormIIcol:nth-child(1),
      li.xoraiFormIIcol:nth-child(2) {
        display: block;
        width: 100%;
        margin: 0 0 15px 0;
      }
    }
  }

  .xoraiFormIcol {
    display: block;
    margin: 0px 0px 10px 0px;
  }

  .xoraiFormContainer {
    display: block;
    padding-top: 1rem;

    label {
      display: block;
      margin-bottom: 0.3rem;
      color: $black;
      font-size: .9rem;
      font-weight: bold;

      .labelRightLink {
        float: right;
        font-weight: normal;
        font-size: .75rem;
        padding-top: 3px;

        a {
          color: $redDark;

          &:hover {
            color: $black;
          }
        }
      }
    }

    input,
    select {
      padding: 0.7rem 0.7rem 0.7rem 3rem;
      font-size: 1rem;
      width: 100%;
      border: 1px solid $offWhiteDark;
      border-radius: 5px;
      box-sizing: border-box;
      background-image: $bgTexure;
    }

    select {
      background: $selectarrow_blackIcon_SVG $offWhiteLight;
      background-position: right 3% center;
    }

    .disabled select {
      background-color: $offWhite;
      background-image: none;
      cursor: not-allowed;
      pointer-events: none;
    }

    input[type="number"] {
      padding-right: 4.5rem;
    }

    .getOtpBtnBlk {
      float: right;
      width: 65px;
      position: relative;
      top: 9px;
      margin-right: 8px;

      .getOtpBtn {
        position: absolute;
        display: block;
        padding: 5px 0;
        border: 0px;
        border-radius: 5px;
        font-size: .75rem;
        color: $white;
        outline: none;
        background: $lightBlack;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: $brownBlack;
        }
      }
    }

    .userIcon,
    .phIcon,
    .otpIcon,
    .xoraiIcon,
    .requestIcon,
    .noIcon,
    .numberIcon,
    .foodIcon,
    .bhokotIcon {
      position: absolute;
      float: left;
      height: 40px;
      aspect-ratio: 1/1;
      border-right: 1px solid $offWhiteDark;
      margin: 1px 0 0 1px;
    }

    .userIcon {
      background: $userFormIcon_SVG;
      background-size: 1rem;
    }

    .phIcon {
      background: $phFormIcon_SVG;
      background-size: 1rem;
    }

    .otpIcon {
      background: $otpFormIcon_SVG;
      background-size: .9rem;
    }

    .xoraiIcon {
      background: $xoraiFormIcon_SVG;
      background-size: 1.25rem;
    }

    .noIcon {
      background: $numFormIcon_SVG;
      background-size: 1rem;
    }

    .requestIcon {
      background: $countFormIcon_SVG;
      background-size: 1rem;
    }

    .numberIcon {
      background: $numberFormIcon_SVG;
      background-size: 1rem;
    }

    .foodIcon {
      background: $foodFormIcon_SVG;
      background-size: 1.1rem;
    }

    .bhokotIcon {
      background: $bhokotFormIcon_SVG;
      background-size: 1.2rem;
    }

    .userIcon,
    .phIcon,
    .otpIcon,
    .xoraiIcon,
    .requestIcon,
    .noIcon,
    .numberIcon,
    .foodIcon,
    .bhokotIcon {
      background-position: center center;
      background-repeat: no-repeat;
    }

    .buttonLabel {
      display: block;
      margin-bottom: 6px;

      input {
        padding-left: none !important;
        font-size: 1.15rem;
      }
    }
  }

  ul.xoraiQRCodeBlk {
    display: block;

    li.xoraiQRCode,
    li.xoraiQRForm {
      display: inline-block;
      vertical-align: top;
      padding: 20px;
      border-radius: 0px;
      margin-right: 15px;
      width: 170px;
      height: 170px;
    }

    li.xoraiQRForm {
      width: -webkit-calc(100% - 195px);
      width: calc(100% - 195px);
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-799) {

      li.xoraiQRCode,
      li.xoraiQRForm {
        display: block;
        width: 100%;
        text-align: center;
        padding: 15px;
        height: auto;
        // margin-bottom: 0px;
      }
    }
  }

  ul.xoraiEventDate {
    display: block;
    margin-top: 1rem;
    border: 1px dashed $offWhiteDark;

    li.xoraiEventDate {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 2);
      width: calc(100% / 2);
      margin: 0px;
      box-shadow: none;
      padding: 5px 8px;
      border-radius: 0px;
      border-bottom: .5px dashed $offWhiteDark;
    }

    li.xoraiEventDate:nth-child(even) {
      margin-right: 0px;
      border-left: .5px dashed $offWhiteDark;
    }

    li.xoraiEventDate:nth-last-child(1) {
      border-bottom: 0px;
    }

    .eventHead,
    .eventDate {
      display: block;
      font-size: .75rem;
      font-weight: bold;
    }

    .eventDate {
      font-weight: normal;
      margin-top: 2px;
    }
  }
}

//Contact
.contact {
  margin-bottom: 20px;

  ul.contactIIcol {
    display: block;
    margin-top: -70px;

    li.contactIIcol {
      display: inline-block;
      vertical-align: top;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      margin-top: 10px;
      background: $white;
      padding: 20px 30px 30px 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      min-height: 450px;

      &:nth-child(1) {
        width: -webkit-calc(100% - 490px);
        width: calc(100% - 490px);
        margin-right: 40px;
      }

      &:nth-child(2) {
        width: 450px;
      }
    }

    @media ($smaller-than: $breakpoint-799) {

      li.contactIIcol {
        display: block;
        width: 100%;
        min-height: auto;

        &:nth-child(1),
        &:nth-child(2) {
          width: 100%;
          margin: 0px;
        }

        &:nth-child(1) {
          margin-bottom: 20px;
        }

        &:nth-child(2) {
          border-radius: 0px;
        }
      }
    }
  }

  .contactFormContainer {
    display: block;
    padding-top: 1.5rem;

    ul.contactFormIIcol {
      display: block;

      li {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 2 - 13px);
        width: calc(100% / 2 - 13px);
        margin: 0px 25px 20px 0px;
        box-shadow: none;
        padding: 0px;
        border-radius: 0px;
        min-height: 0px;

        &:nth-child(even) {
          margin-right: 0px;
        }
      }

      @media ($smaller-than: $breakpoint-799) {
        li {
          display: block;
          width: 100%;
        }
      }

      .singleRow {
        display: block;
        width: 100%;
        margin: 0px 0px 25px 0px;
      }
    }

    label {
      display: block;
      padding-bottom: .2rem;
      color: $black;
      font-size: .9rem;
      font-weight: bold;
    }

    input,
    select,
    textarea {
      padding: 0.7rem 0.7rem 0.7rem 3rem;
      font-size: 1rem;
      width: 100%;
      border: 1px solid $offWhiteDark;
      border-radius: 5px;
      box-sizing: border-box;
      background-image: $bgTexure;
    }

    textarea {
      height: 80px;
      padding-top: .5rem;
    }

    .userIcon,
    .mailIcon,
    .phIcon,
    .subjectIcon,
    .msgIcon {
      position: absolute;
      float: left;
      height: 40px;
      aspect-ratio: 1/1;
      border-right: 1px solid $offWhiteDark;
      margin: 1px 0 0 1px;
    }

    .userIcon {
      background: $userFormIcon_SVG;
      background-size: 1rem;
    }

    .mailIcon {
      background: $mailFormIcon_SVG;
      background-size: 1rem;
    }

    .phIcon {
      background: $phFormIcon_SVG;
      background-size: 1rem;
    }

    .subjectIcon {
      background: $subjectFormIcon_SVG;
      background-size: .9rem;
    }

    .msgIcon {
      background: $msgFormIcon_SVG;
      background-size: 1.1rem;
    }

    .userIcon,
    .mailIcon,
    .phIcon,
    .subjectIcon,
    .msgIcon {
      background-position: center center;
      background-repeat: no-repeat;
    }

    .msgIcon {
      height: 79px;
      width: 40px;
      background-position: top 10px center;
    }

    // .buttonLabel {
    //   width: 100px;
    //   margin-top: -10px;

    //   input {
    //     padding-left: 0.7rem;
    //   }
    // }

    .buttonLabel {
      width: 100px;
      margin-top: -10px;

      .submitButton {
        padding: 0.65rem 1rem;
        font-size: 1rem;
        font-weight: bold;
        color: $white;
        background: $redDark;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        align-self: center;

        &:hover {
          background-color: $red;
        }
      }

      @media ($smaller-than: $breakpoint-799) {
        .submitButton {
          padding: 0.4rem 0rem;
          font-size: .8rem;
          background: rgba(127, 20, 22, 0.85);
        }
      }
    }

    .successMessage,
    .errorMessage {
      color: $success;
      margin-top: 10px;
      border-radius: 5px;
      text-align: center;
      font-weight: bold;
      font-size: .85rem;
      background: $white;
      padding: 5px 8px;
      border: 1px solid $offWhiteDark;
    }
    
    .errorMessage {
      color: $alert;
    }
  }

  ul.contactInfo {
    display: block;

    li.contactInfo {
      display: inline-block;
      vertical-align: top;
      box-shadow: none;
      margin: 10px 10px 0px 0px;
      padding: 0px;
      border-radius: 0px;
      min-height: 0px;

      &:nth-child(odd) {
        width: 40px;
        padding-top: 5px;
      }

      &:nth-child(even) {
        width: -webkit-calc(100% - 70px);
        width: calc(100% - 70px);
        font-size: 1rem;
      }

      .mailIcon,
      .phIcon,
      .addressIcon {
        height: 40px;
        aspect-ratio: 1/1;
      }

      .mailIcon {
        background: $mailFormIcon_SVG;
        background-size: 1.35rem;
      }

      .phIcon {
        background: $phFormIcon_SVG;
        background-size: 1.35rem;
      }

      .addressIcon {
        background: $locationFormIcon_SVG;
        background-size: 1.35rem;
      }

      .mailIcon,
      .phIcon,
      .addressIcon {
        background-position: top left;
        background-repeat: no-repeat;
      }
    }
  }
}

//Events
.events {
  margin-bottom: 20px;

  .eventIcol {
    display: block;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    margin-top: -60px;
    background: $white;
    padding: 20px 30px 30px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 450px;
  }

  ul.eventIIcol {
    display: block;

    li.eventIIcol {
      display: inline-block;
      vertical-align: top;
      margin-top: 20px;
      padding: 0px;

      &:nth-child(1) {
        width: -webkit-calc(100% - 400px);
        width: calc(100% - 400px);
      }

      &:nth-child(2) {
        width: 400px;
        padding-left: 50px;
      }
    }

    @media ($smaller-than: $breakpoint-799) {

      li.eventIIcol,
      li.eventIIcol:nth-child(1),
      li.eventIIcol:nth-child(2) {
        display: block;
        width: 100%;
        padding: 0px;
      }
    }
  }
}

//About Us
.aboutUs {
  padding: 20px;
  background-color: $white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: .9rem;
  overflow-x: auto; // Add horizontal scrolling for large tables
  margin-bottom: 20px;
}

ul.bhokotDisplay {
  display: block;
  margin-top: 20px;

  li.bhokotBlk {
    display: inline-block;
    vertical-align: top;
    width: -webkit-calc(100% / 4 - 15px);
    width: calc(100% / 4 - 15px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin: 0 20px 20px 0;
    border: 1px solid $offWhite;
    background: $white;
    text-align: center;

    &:nth-child(4n + 0) {
      margin-right: 0px;
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    li.bhokotBlk {
      display: block;
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  .bhokotPhotoBlk {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  .member-info {
    margin-top: 10px;
  }

  .member-name {
    font-size: 1.1rem;
    margin: 5px 0;
    color: $blueDark;
  }

  .member-contact {
    color: $brownBlack;
  }
}

.executiveComiteeTable {
  display: block;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid $offWhiteDark;
      padding: 8px 8px;
      text-align: left;
      vertical-align: top;
      font-size: 0.9125rem;
    }

    th {
      background-color: $offWhite;
    }

    @media ($smaller-than: $breakpoint-450) {

      th,
      td {
        font-size: 0.875rem;
      }

      th:first-child,
      td:first-child {
        display: none;
      }
    }
  }
}




/* Responsive styles */
@media (max-width: 1200px) {
  .member-block {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .member-block {
    width: 100%;
  }
}

// Pop style for Donate Form
.popupDonateForm {
  background: $white;
  border-radius: 10px;
  padding-bottom: 10px;

  .donationFormContainer {
    display: block;

    .donateTypeAmtBlk {
      display: block;
      padding: 10px 15px 15px 15px;
      background: $redDark;
      color: $white;
      border-radius: 5px;
      margin-bottom: 10px;

      li {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% - 140px);
        width: calc(100% - 140px);
        margin: 0 15px 0 0;
        box-shadow: none;
        padding: 0px;
      }

      li:nth-child(even) {
        width: 125px;
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-450) {

        li,
        li:nth-child(even) {
          display: block;
          width: 100%;
        }

        li:nth-child(2) {
          margin-top: 10px;
        }
      }

      .labelWhite {
        display: block;
        margin-bottom: 0.25rem;
        color: $white;
        font-size: .75rem;
        font-weight: bold;

        .spclText {
          float: right;

          a {
            color: $offWhite;
            font-weight: normal;
            font-size: .7rem;

            &:hover {
              color: $white;
            }
          }
        }
      }

      input,
      select {
        padding: 0.6rem 0.6rem 0.6rem 0.6rem;
        font-size: .9rem;
        font-weight: bold;
        color: $brownBlack;
        width: 100%;
        border: 0px;
        border-radius: 5px;
        box-sizing: border-box;
      }

      select {
        background: $selectarrow_blackIcon_SVG $offWhiteLight;
        background-position: right 5px center;
      }

      input[type="number"] {
        padding-left: 2rem;
      }

      .rupeeIcon {
        position: absolute;
        float: left;
        height: 38px;
        aspect-ratio: 1/1;
        margin: 1px 0 0 1px;
        background: $rupeeFormIcon_SVG;
        background-size: .65rem;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    .donateFormIIcol,
    .donateFormIcol {
      display: block;
      padding: 0 5px;

      li {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 2 - 8px);
        width: calc(100% / 2 - 8px);
        margin: 0px 15px 10px 0px;
        box-shadow: none;
        padding: 0;
      }

      li:nth-child(even) {
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-450) {

        li,
        li:nth-child(even) {
          display: block;
          width: 100%;
        }
      }

      label {
        display: block;
        margin-bottom: 0.1rem;
        color: $lighterBlack;
        font-size: .75rem;
        font-weight: bold;
      }

      input {
        padding: 0.5rem 0.7rem 0.5rem 3rem;
        font-size: 1rem;
        width: 100%;
        border: 1px solid $offWhiteDark;
        border-radius: 5px;
        box-sizing: border-box;
        background-image: $bgTexure;
      }

      input[type="number"] {
        padding-right: 3.75rem;
      }

      .getOtpBtnBlk {
        float: right;
        width: 58px;
        position: relative;
        top: 7px;
        margin-right: 5px;

        .getOtpBtn {
          position: absolute;
          display: block;
          padding: 5px 0;
          border: 0px;
          border-radius: 5px;
          font-size: .7rem;
          color: $white;
          outline: none;
          background: $lightestBlack;
          text-align: center;
          cursor: pointer;

          &:hover {
            background: $brownBlack;
          }
        }
      }
    }

    .donateFormIcol {

      li {
        display: block;
        width: 100%;
        margin: 0px 15px 10px 0px;
        box-shadow: none;
        padding: 0;
      }
    }

    .userIcon,
    .mailIcon,
    .phIcon,
    .locationIcon,
    .otpIcon {
      position: absolute;
      float: left;
      height: 35px;
      aspect-ratio: 1/1;
      border-right: 1px solid $offWhiteDark;
      margin: 1px 0 0 1px;
    }

    .userIcon {
      background: $userFormIcon_SVG;
      background-size: .9rem;
    }

    .mailIcon {
      background: $mailFormIcon_SVG;
      background-size: .9rem;
    }

    .phIcon {
      background: $phFormIcon_SVG;
      background-size: .8rem;
    }

    .locationIcon {
      background: $locationFormIcon_SVG;
      background-size: .75rem;
    }

    .otpIcon {
      background: $otpFormIcon_SVG;
      background-size: .8rem;
    }

    .userIcon,
    .mailIcon,
    .phIcon,
    .locationIcon,
    .otpIcon {
      background-position: center center;
      background-repeat: no-repeat;
    }

    .buttonLabel {
      display: block;
      margin-top: 0px;
      text-align: center;

      .buttonDonate {
        display: inline-block;
        vertical-align: top;
        width: auto;
        padding: 8px 20px 10px 20px;
        border: 0px;
        border-radius: 7px;
        outline: none;
        font-size: .9175rem;
        font-weight: bold;
        background-color: $redDark;
        color: $white;
        cursor: pointer;

        &:hover {
          background-color: $red;
        }
      }

      input {
        padding-left: 0.7rem;
      }
    }
  }
}