@import "support";

.header,
.headerInpage,
.headerResponsive {
  display: block;
  width: 100%;
  visibility: visible;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 999;

  .logo {
    display: inline-block;
    vertical-align: top;

    .logoPosition {
      height: 100px;
      width: 350px;
      margin: 20px 0px 0px 0px;
      background-position: left top;
      background-size: 300px;
    }
  }

  .Nav_RightContentBlk,
  .headerTopRight,
  .headerTopRightInpage {
    float: right;
    margin-top: 20px;

    .topRight_btnLabel {
      margin: 0px 0px 0px 0px;
      float: right;

      .donate_button {
        display: inline-block;
        vertical-align: middle;
        padding: 0px 15px;
        height: 33px;
        margin-left: 5px;
        font-size: .9rem;
        font-weight: bold;
        color: $white;
        border: none;
        background: $redDark;
        outline: none;
        border-radius: 5px;
        text-transform: uppercase;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      .donate_button:hover {
        cursor: pointer;
        background: $redDark;
      }

      .login_button {
        display: inline-block;
        vertical-align: middle;
        width: 32px;
        height: 32px;
        border: 1px solid $offWhiteDark;
        background: $loginIcon_SVG;
        background-size: 15px;
        background-position: center center;
        background-color: $white;
        outline: none;
        border-radius: 5px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
      }

      .login_button:hover {
        background-color: $offWhite;
      }
    }

    .topnav {
      overflow: hidden;

      .logoPosInpage {
        height: 46px;
        width: 90px;
        background-position: left top;
        margin: -6px 0px 0px 0px;
      }

      .line_topnav {
        display: inline-block;
        vertical-align: middle;
        height: 15px;
        width: 18px;
        background: $menuIcon_SVG;
        background-size: 12px;
        background-position: top right;
        margin-top: -3px;
      }

      .arrow_down_topnav {
        display: inline-block;
        vertical-align: middle;
        margin: -5px 0 0 2px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $lightBlack;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      .dropdown {
        float: left;
        overflow: hidden;

        .dropbtn {
          border: none;
          outline: none;
          color: $black;
          font-weight: bold;
          padding: 8px 10px 8px 10px;
          font-size: 1rem;
          margin: 0;
          background: transparent;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          border-radius: 5px;
        }

        @-moz-document url-prefix() {
          .dropbtn {
            padding: 12px 16px 11px 16px;
          }
        }

        .dropbtn:hover {
          cursor: pointer;
        }

        .dropbtn.active {
          // color: $color-red-darker;
          background: $offWhite;
          background-image: $bgTexure;
        }

        .dropbtn.active:hover {
          background: $offWhite;
          background-image: $bgTexure;
          color: $black;
        }

        .dropdown-content {
          display: none;
          position: absolute;
          min-width: 150px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 998;

          a {
            display: block;
            float: none;
            color: $black;
            font-weight: bold;
            font-size: .9rem;
            padding: 8px 20px 8px 16px;
            text-decoration: none;
            text-align: left;
            background: $white;
            border-bottom: 1px solid $black;
          }

          a:hover {
            color: $redDark;
            background-image: $bgTexure;
          }

          a:last-child {
            border-bottom: none;
          }
        }
      }

      .dropdown:hover .arrow_down_topnav {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $redDark;
        border-top: none;
      }

      .dropdown:hover .line_topnav {
        background: none;
        margin: -5px 0 0 8px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $redDark;
      }

      .dropdown:hover .dropbtn.active {
        background: $offWhite;
        background-image: $bgTexure;
        color: $black;
      }

      .dropdown:hover .dropbtn {
        background: $offWhite;
        background-image: $bgTexure;
        color: $black;
      }

      .dropbtn.active:hover {
        color: $white;
      }

      .dropdown:hover .dropdown-content {
        display: block;
      }
    }
  }
}

.headerResponsive {
  display: none;
  position: fixed;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  background: $offWhiteLight;

  .topnav {
    overflow: hidden;
    margin-left: 0px;

    .line_topnav {
      display: inline-block;
      vertical-align: middle;
      height: 15px;
      width: 18px;
      background: $menuIcon_SVG;
      background-size: 12px;
      background-position: top right;
      margin-top: -3px;
    }

    .arrow_down_topnav {
      display: inline-block;
      vertical-align: middle;
      margin: -5px 0 0 2px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $lightBlack;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    a {
      display: block;
      float: left;
      color: $white;
      text-align: center;
      padding: 5px 8px 0px 8px;
      text-decoration: none;
      font-size: .8rem;
      background: $white;
      background-image: $bgTexure;
    }

    @-moz-document url-prefix() {
      a {
        padding: 7px 16px 7.3px 16px;
      }
    }

    .logoPosition {
      display: inline-block;
      height: 42px;
      width: 180px;
      background-position: left top;
      background-size: 180px;
      margin: -3px 0px 0px 0px;

    }

    .icon {
      display: none;
      height: 40px;
      width: 40px;
      background: $menuIcon_SVG;
      background-size: 22px;
      background-position: center center;
      border-left: 1px solid $offWhite;
      margin-top: 5px;
    }

    .icon:hover {
      background: $menuIconRed_SVG;
      background-size: 22px;
      background-position: center center;
    }

    .dropdown {
      float: left;
      overflow: hidden;

      .dropbtn {
        border: none;
        outline: none;
        color: $blueDark;
        padding: 13px 17px 11px 16px;
        font-size: 1rem;
        margin: 0;
        background: transparent;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }

      @-moz-document url-prefix() {
        .dropbtn {
          padding: 12px 16px 11px 16px;
        }
      }

      .dropbtn:hover {
        cursor: pointer;
      }

      .dropbtn.active {
        color: $redDark;
        background: $offWhiteLight;
        background-image: $bgTexure;
      }

      .dropbtn.active:hover {
        background: $offWhiteLight;
        background-image: $bgTexure;
        color: $blueDark;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        min-width: 160px;
        // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 998;

        a {
          display: block;
          float: none;
          color: $brownBlack;
          padding: 8px 20px 8px 16px;
          text-decoration: none;
          text-align: left;
          background: $white;
          border-bottom: 1px solid $redDark;
        }

        a:hover,
        a:first-child:hover {
          color: $redDark;
          background-image: $bgTexure;
        }

        a:last-child {
          border-bottom: none;
        }
      }
    }

    .dropdown:hover .arrow_down_topnav {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $redDark;
      border-top: none;
    }

    .dropdown:hover .line_topnav {
      background: none;
      margin: -5px 0 0 5px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $redDark;
    }

    .dropdown:hover .dropbtn.active {
      background: $offWhiteLight;
      background-image: $bgTexure;
      color: $black;
    }

    .dropdown:hover .dropbtn {
      background: $offWhiteLight;
      background-image: $bgTexure;
      color: $black;
    }

    .dropbtn.active:hover {
      color: $white;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    a {
      display: inline-block;
      vertical-align: middle;
    }

    .topnav a:not(:first-child),
    .dropdown .dropbtn {
      display: none;
    }

    .topnav a.icon {
      float: right;
      display: block;
    }

    .topnav.responsive {
      position: relative;
      margin-left: 0px;
      padding-left: 0px;
    }

    .topnav.responsive .icon {
      position: absolute;
      height: 40px;
      width: 40px;
      right: 0;
      top: 0;
      background: $menuCrossRed_SVG;
      background-size: 16px;
      background-position: center center;
      border-left: 1px solid $offWhiteLight;
    }

    .topnav.responsive a {
      display: block;
      float: none;
      text-align: left;
    }

    .topnav.responsive .dropdown {
      float: none;
    }

    .topnav.responsive .dropdown-content {
      position: relative;

      a {
        color: $black;
        margin-left: -5px;
      }

      a::before {
        content: "\0023f5";
        margin-right: 3px;
      }

      a:last-child {
        border-bottom: 0.5px solid $offWhiteLight;
      }
    }

    .topnav.responsive .dropdown .dropbtn {
      display: block;
      width: 100%;
      text-align: left;
      font-size: 1rem;
      color: $black;
      padding-left: 10px;
    }

    // .dropdown:hover .dropbtn {
    //     border-top: 1px solid $color-lighterwhite;
    // }
  }

  .navBlockResponsive {
    padding: 7px 0px;
  }

  .headerTopRight {
    margin-top: 12px;
  }

  @media ($smaller-than: $breakpoint-1200) {
    .headerTopRight {
      position: absolute;
      right: 50px;
      top: 0px;
      z-index: 120;
    }
  }
}

.headerInpage {
  display: block;
  background: $white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

  .logo {

    .logoPosition {
      height: 60px;
      width: 220px;
      background-position: left top;
      background-size: 220px;
      margin: 10px 0px 0px 0px;
    }
  }
}

@media ($smaller-than: $breakpoint-999) {

  .header,
  .headerInpage {
    display: none;
  }

  .headerResponsive {
    display: block;
  }
}