@import "support";

.si_su_Layout {
  overflow: hidden;
  z-index: 2500;
  position: fixed;
  right: -2000px;
  top: 0;
  width: 350px;
  height: 100%;
  padding: 0rem 2rem 1.5rem 2rem;
  background: $white;
  background-image: $bgTexure;

  .si_su_Close {
    position: absolute;
    z-index: 1000;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 1.20rem;
    font-weight: bold;
    color: $white;
    padding: 0px 6px 3px 6px;
    background: $lightBlack;
  }

  .si_su_Close:hover {
    background: $redDark;
  }

  .si_su_Close:before {
    content: "x";
  }

  .auth_container {
    display: block;
    margin-top: 2rem;

    .authLogoPos {
      height: 80px;
      width: 100%;
      background-position: top center;
      background-size: 95px;
    }

    .authHead,
    .authSubHead {
      display: block;
      font-size: 1.1rem;
      font-weight: bold;
      text-align: center;
      color: $redDark;
      margin: .85rem 0 0 0;
    }

    .authSubHead {
      font-size: 0.75rem;
      margin: 0 0 1.5rem 0;
      color: $lightBlack;
    }

    .form-group {
      margin-bottom: .75rem;
      width: 100%;

      label {
        font-weight: bold;
        text-align: left;
        font-size: .9rem;
      }

      input {
        margin-top: .1rem;
        padding: 0.6rem;
        border: 1px solid $offWhiteDark;
        border-radius: 5px;
        background-color: $offWhite;
      }

      .getOtpBtnBlk {
        float: right;
        width: 70px;
        position: relative;
        top: 25px;
        margin-right: 2px;

        .getOtpBtn {
          position: absolute;
          display: block;
          padding: 6px 8px 5px 8px;
          border: 0px;
          border-radius: 5px;
          font-size: .8rem;
          color: $white;
          outline: none;
          background: $lightBlack;
          text-align: center;
        }

        .getOtpBtn:hover {
          background: $black;
          cursor: pointer;
        }
      }

      ul.otpMsg {
        display: block;
        margin: 0px;
        padding: 0px;

        li.otpMsg {
          display: inline-block;
          vertical-align: top;
          width: 100px;
        }

        li.otpMsg:nth-child(even) {
          width: calc(100% - 100px);
          width: -webkit-calc(100% - 100px);
          padding-left: 10px;
          font-size: .75rem;
          color: $lighterBlack;
          padding-top: 5px;
        }
      }

      .resendOtpBtn {
        display: block;
        padding: 6px 0px;
        width: 100px;
        margin: 0px auto;
        border: 0px;
        border-radius: 5px;
        font-size: .8rem;
        color: $white;
        outline: none;
        background: $lightBlack;
        text-align: center;
        cursor: pointer;
      }

      .resendOtpBtn:hover {
        background: $black;
      }
    }

    .invalidOTP {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 1.2rem 0px;
      width: 100%;
      font-size: .9rem;
      font-weight: bold;
      color: $redDark;

      &::before,
      &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid $lightBlack;
      }

      &:not(:empty)::before {
        margin-right: 0.35em;
      }

      &:not(:empty)::after {
        margin-left: 0.35em;
      }
    }

    .authTerms {
      display: block;
      font-size: .75rem;
      color: $lightBlack;
      margin: 1.5rem 0 1rem 0;
      text-align: center;

      a {
        color: $blue;
        font-weight: bold;
        border-bottom: 1px dashed $blue;
      }
    }

    .authText {
      display: block;
      font-size: .9rem;
      color: $lightBlack;
      text-align: center;
      align-items: center;
      margin-top: 1.35rem;
    }

    .toggle-button {
      color: $redDark;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      width: 100%;
      background: transparent;
      outline: none;
      border: none;
      text-decoration: underline;
    }

    //Member Card Style
    .memberCard {
      display: block;
      margin: 10px -10px 20px -10px;

      ul.logoAddressPhoto {
        display: block;
        padding: 10px 5px 0 5px;
        border-radius: 10px 10px 0 0;
        background-image: $bgTexure;
        background-color: $redDark;

        li.logoAddressPhoto {
          display: inline-block;
          vertical-align: top;

          &:first-child {
            width: 70px;
            padding-top: 8px;
          }

          &:nth-child(2) {
            width: calc(100% - 140px);
            width: -webkit-calc(100% - 140px);
            padding-left: 5px;
          }

          &:last-child {
            width: 70px
          }

          .mcLogoPos {
            height: 80px;
            width: 100%;
            background-position: top center;
            background-size: 68px;
          }

          .sscsbName {
            display: block;
            border-bottom: 1px solid $offWhite;
            font-size: .83rem;
            line-height: .8175rem;
            color: $redDark;
            font-family: $font-arialBlack;
            padding: 7px 0;
            color: $white;
          }

          .sscsbAdd {
            display: block;
            font-size: .65rem;
            color: $offWhite;
            padding-top: 3px;
          }

          .profilePhoto {
            float: right;
            position: absolute;
            margin-top: 10px;
          }

          .photo-uploaders {
            display: flex;
            justify-content: space-around;
            margin: 0 20px 0px 0px;
            padding: 0px;

            &:last-child {
              margin-right: -10px;
            }
          }

          .photo-uploader {
            display: flex;
            flex-direction: column;
            align-items: center;

            .photo-label {
              margin: 5px 0 0 0;
              font-weight: bold;
              font-size: .75rem;
            }

            .photo-container {
              width: 70px;
              aspect-ratio: 1/1;
              border: 3px solid $redDark;
              background: $white;
              border-radius: 10px;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;

              .browse-btn {
                display: inline-block;
                padding: 5px 12px;
                color: $brownBlack;
                text-align: center;
                cursor: pointer;
                font-size: 0.7rem;
              }

              .photo {
                max-width: 100%;
                max-height: 100%;
              }

              .delete-btn {
                position: absolute;
                top: 5px;
                right: 5px;
                background: none;
                border: none;
                font-size: 0.75rem;
                cursor: pointer;
                color: $redDark;
              }
            }
          }
        }
      }

      .memberDetails {
        display: block;
        padding: 15px 5px 5px 10px;
        border-radius: 0 0 10px 10px;
        border: 1px solid $brownBlack;
        background-image: $bgTexure;

        ul.memberData {
          display: block;

          li.memberData {
            display: inline-block;
            vertical-align: top;
            font-size: .75rem;
            margin-bottom: 8px;

            &:nth-child(odd) {
              width: 95px;
              font-weight: bold;
            }

            &:nth-child(even) {
              width: calc(100% - 95px);
              width: -webkit-calc(100% - 95px);

              &::before {
                content: ':';
                margin-right: 5px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .memberButtonBlk {
      display: block;
      text-align: center;
      margin: 8px 0 25px 0;
    }

    .memberTranHeading {
      display: block;
      font-size: .9rem;
      font-weight: bold;
      color: $black;
      margin-bottom: 5px;
    }

    //Transaction Table
    .memberTranHeadTable {
      width: 100%;
      border: 1px solid $disable;
      border-radius: 10px 10px 10px 10px;

      table {
        width: 100%;
        border-collapse: collapse;

        thead {
          th {
            font-size: .75rem;
            font-weight: bold;
            padding: 5px;
            border-bottom: 1px solid $disable;
            background-image: $bgTexure;
            text-align: left;

            // Date column
            &:nth-child(1) {
              width: 80px;
              padding-left: 10px;
            }

            // Type column
            &:nth-child(2) {
              width: calc(100% - 190px);
              width: -webkit-calc(100% - 190px);
            }

            // Amount column
            &:nth-child(3) {
              width: 70px;
              text-align: right;
            }

            // Download column
            &:nth-child(4) {
              width: 40px;
              padding-right: 10px;
            }
          }
        }
      }
    }

    .memberTranTable {
      min-height: 50px;
      max-height: 170px;
      overflow-y: scroll; // Vertical scroll
      display: block;
      width: 100%;
      margin-bottom: 5px;

      table {
        width: 100%;
        border-collapse: collapse;

        tbody {
          td {
            font-size: .75rem;
            padding: 7px 5px;
            border-bottom: 1px solid $offWhiteDark;
            text-align: left;

            // Date column
            &:nth-child(1) {
              width: 80px;
              padding-left: 10px;
            }

            // Type column
            &:nth-child(2) {
              width: calc(100% - 175px);
              width: -webkit-calc(100% - 175px);
            }

            // Amount column
            &:nth-child(3) {
              width: 70px;
              text-align: right;
            }

            // Download column
            &:nth-child(4) {
              width: 25px;
              text-align: center;
            }
          }

          tr {
            &:nth-child(even) {
              background-color: $offWhiteLight; // Optional: striped rows for better readability
            }
          }

          tr:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }

    .downloadIcon {
      display: block;
      width: 20px;
      aspect-ratio: 1/1;
      // background-image: url(../img/download.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 14px;
    }
  }
}